import React from 'react';

import './fancy-radio.scss';

const FancyRadio = ({ text }) => {
	return (
		<div className='fancy-radio-holder'>
			<div className='fancy-dot'></div>
			<div>{text}</div>
		</div>
	);
};

export default FancyRadio;
